div.filters {
  position: sticky;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: #bf8034;
  text-align: center;
  z-index: 100;
  box-shadow: 0 0 5px black;
  padding: 0.3rem;
}
div.filters input[type="search"] {
  padding: 1rem;
  background-color: #a5702f;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
  width: 300px;
}
div.filters select {
  padding: 1rem;
  border: none;
  outline: none;
  background-color: #a5702f;
  color: white;
  border-radius: 10px;
  margin-left: 1rem;
}
