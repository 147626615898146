@import url("https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  background-color: #202020;
  color: white;
  font-family: sans-serif;
}
div.pokemons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
::selection {
  background-color: #9c9c9c;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
